import React from "react";

const Bars = () => {
  return (
    <svg
      className="text-white lg:hidden"
      fill="#FFFFFF"
      width="2rem"
      height="2rem"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Bars</title>
      <path d="M4 4.76h24c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0h-24c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0zM28 27.25h-24c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h24c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM28 19.213h-24c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h24c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM28 11.25h-24c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h24c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z"></path>
    </svg>
  );
};

export default Bars;
